import React from 'react';

import './styles.css';

export default ({
    children,
    href = "#",
    onClick = false,
}) => {
    return (
        <a className="cool-link" href={href} onClick={() => onClick ? onClick() : console.log("")}>{children}</a>
    )
}