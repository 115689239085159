
import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Iframe from 'react-iframe'

import translate from '../../_helpers/translate';
import Button from '../../components/Button';
import APP_STORE_LOGO_ACTIVE from '../../assets/icon/apple_active.png'
import APP_STORE_LOGO_INACTIVE from '../../assets/icon/apple_inactive.png'
import PLAY_STORE_LOGO_ACTIVE from '../../assets/icon/android_active.png'
import PLAY_STORE_LOGO_INACTIVE from '../../assets/icon/android_inactive.png'
import VIDEOPLAYER_WRAP from '../../assets/images/videoplayer_wrap.png'
import LanguageSelector from './LanguageSelector';
import constants from '../../constants';


const LeftSection = () => {
    return (
        <>
            <div className='top-spacing'></div>
            <a href='http://evoqueinnovativelab.com/' target="_blank" >
                <h5 style={{ color: constants.COLOR.DARK_GREEN }}>{translate("welcome-to-paper-free-solutions")}</h5>
            </a>
            <h1 className='txt-bold'>Mobile Application</h1>
            <h1 className='txt-bold'>Development Company</h1>
            <text>{translate("our-aim-is-to-create-user-friendly-technologiesfor-the-common-people-by-reducing-the-user-of-paper")}</text>
            <div className='top-spacing-section' />
            <div style={{ paddingLeft: '20px' }}>
                <StoreIconDisplay />
            </div>
        </>
    )
}

const RightSection = () => {
    return (
        <>
            <div style={{ position: "relative", }} >
                <img src={VIDEOPLAYER_WRAP} style={{
                    width: '460px',
                    position: 'absolute',
                    zIndex: '999',
                    height: '460px'
                }} />
                <Iframe url={`http://www.youtube.com/embed/linlz7-Pnvw?autoplay=1&origin=${constants.HOMPAGE}`}
                    width="450px"
                    height="450px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                    autoplay
                     />
            </div>
            {/* </div> */}
        </>
    )
}

const StoreIconDisplay = () => {
    const [APPLE_LOGO, setAppleLogo] = useState(APP_STORE_LOGO_INACTIVE)
    const [ANDROID_LOGO, setAndroidLogo] = useState(PLAY_STORE_LOGO_INACTIVE)
    return (
        <Row >
            <div className='section-vertical'>
                <Row>
                    <Col>
                        <img src={APPLE_LOGO} alt="store-logo" className="store-logo"
                            onMouseOver={e => setAppleLogo(APP_STORE_LOGO_ACTIVE)}
                            onMouseOut={e => setAppleLogo(APP_STORE_LOGO_INACTIVE)}
                        />
                    </Col>
                    <Col>
                        <img src={ANDROID_LOGO} alt="store-logo" className="store-logo"
                            onMouseOver={e => setAndroidLogo(PLAY_STORE_LOGO_ACTIVE)}
                            onMouseOut={e => setAndroidLogo(PLAY_STORE_LOGO_INACTIVE)}
                        />
                    </Col>
                </Row>
                <div className='section-vertical' />
            </div>
        </Row>
    )
}

export default function HomePage(props) {

    return (
        <Container className="app section-vertical">
            <Row >
                <Col sm={12} md={7}>
                    <LeftSection />
                </Col>
                <Col sm={12} md={5}>
                    <RightSection />
                </Col>
            </Row>
            <LanguageSelector />
        </Container>
    );
}