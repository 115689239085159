import React from 'react';

export default ({
    label = '',
    showBarRight = false,
    onClick = false,
    style = { },
    data = ""
}) => {
    return (
        <>
            <text onClick={() => onClick ? onClick(data) : console.log('')} style={style}> {`${label} ${showBarRight ? ' | ' : ' '}`} </text>
        </>
    )
}