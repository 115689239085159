import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import reportWebVitals from './reportWebVitals';
import HomePage from './pages/HomePage';
import AOS from 'aos';

const hist = createBrowserHistory();

AOS.init();

ReactDOM.render(
  <React.StrictMode>
    <Router history={hist}>
      <Switch>
        <Route path="/" component={HomePage} />
        <Route path="*" component={() => "ERROR 404 Page not found. Please reload"} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
