import React, {useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import constants from '../../constants';
import Button from '../../components/Button';
import TextBox from '../../components/TextBox';
import HowerLine from '../../components/HowerLine';

import IMG_PHONE_INACTIVE from '../../assets/icon/phone_inactive.png'
import IMG_PHONE_ACTIVE from '../../assets/icon/phone_active.png'
import IMG_MAIL_INACTIVE from '../../assets/icon/mail_inactive.png'
import IMG_MAIL_ACTIVE from '../../assets/icon/mail_active.png'
import IMG_LOCATION_INACTIVE from '../../assets/icon/location_inactive.png'
import IMG_LOCATION_ACTIVE from '../../assets/icon/location_active.png'
import IMG_CLOCK_INACTIVE from '../../assets/icon/clock_inactive.png'
import IMG_CLOCK_ACTIVE from '../../assets/icon/clock_active.png'

export default function ContactUS(props) {
    return (
        <div className='App'>
            <Container>
                <HowerLine>
                    <h2 className='txt-bold' style={{ color: constants.COLOR.DARK_GREEN }}> Contact us </h2>
                </HowerLine>
                <div className='top-spacing-section' />
                <Row>
                    <LeftSection />
                    <RigthSection />
                </Row>
                <div className='top-spacing-section' />
                <Row style={Styles.btnRow}>
                    <Button btnColor="dark-green" label={'Send'} btnStyle={Styles.btn} txtStyle={Styles.txt} />
                </Row>
            </Container>
        </div>
    )
}

const LeftSection = () => {
    const [phoneImg, setPhoneImg] = useState(IMG_PHONE_INACTIVE)
    const [mailImg, setMailImg] = useState(IMG_MAIL_INACTIVE)
    const [locationImg, setLocationImg] = useState(IMG_LOCATION_INACTIVE)
    const [clockImg, setClockImg] = useState(IMG_CLOCK_INACTIVE)

    return (
        <Col sm={4}>
            <Container>
                <div className='top-spacing-section' />
                <ImageText img={phoneImg} onMouseOver={() => setPhoneImg(IMG_PHONE_ACTIVE)} onMouseOut={() => setPhoneImg(IMG_PHONE_INACTIVE)} label='+91 9876543210' />
                <ImageText img={mailImg} onMouseOver={() => setMailImg(IMG_MAIL_ACTIVE)} onMouseOut={() => setMailImg(IMG_MAIL_INACTIVE)} label='name@paperfree.com' />
                <ImageText img={locationImg} onMouseOver={() => setLocationImg(IMG_LOCATION_ACTIVE)} onMouseOut={() => setLocationImg(IMG_LOCATION_INACTIVE)} label='Udupi, Karnataka' />
                <ImageText img={clockImg} onMouseOver={() => setClockImg(IMG_CLOCK_ACTIVE)} onMouseOut={() => setClockImg(IMG_CLOCK_INACTIVE)} label='Mon-Sat | 9 AM - 7PM' />
            </Container>
        </Col>
    )
}

const ImageText = ({ img, label = '', style = {}, onMouseOver, onMouseOut }) => {
    return (
        <Row style={{ display: 'flex', marginBottom: 20 }}>
            <div style={{ justifyContent: 'center' }}>
                <img src={img} className='icon' style={{pointerEvents: 'all'}}
                 onMouseOver={onMouseOver}
                 onMouseOut={onMouseOut}
                />
                <text className='txt-bold' style={{ marginLeft: 20, color: constants.COLOR.DARK_GREEN, ...style }}>{label}</text>
            </div>
        </Row>
    )
}

const RigthSection = () => {
    return (
        <>
            <Col>

                <Row style={{ display: 'flex', alignSelf: "center" }}>
                    <Col style={{ display: 'flex', justifyContent: "center", alignSelf: "center", marginBottom: "20px" }}>
                        <h2 className='txt-bold' style={Styles.h2}> Write to us</h2>
                    </Col>
                </Row>
                <div className='top-spacing-section' />
                <Row>
                    <Col>
                        <TextBox label={'Name'} />
                    </Col>
                    <Col>
                        <TextBox label={'Phone'} />
                    </Col>
                </Row>
                <Section>
                    <TextBox label={'Email'} style={{}} />
                </Section>
                <Section>
                    <TextBox label={'Brief about the project'} rows='10' multiline />
                </Section>
            </Col>
        </>
    )
}

const Section = ({ children }) => <Row><Col> {children} </Col></Row>

const Styles = {
    h2: { color: constants.COLOR.DARK_GREY, right: 0, position: 'absolute' },
    btn: { backgroundColor: constants.COLOR.DARK_GREEN, width: '20vmin', paddingTop: 5, paddingBottom: 5 },
    txt: { color: constants.COLOR.WHITE },
    btnRow: { justifyContent: 'center', alignItems: "center", alignContent: 'center' },
}