import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Animate from '../../components/Animate';
import MOCK_IMAGE from '../../assets/images/LineSaleMockUp.png'
import MOCK_HUMAN_IMAGE from '../../assets/images/LineSaleMockHuman.png'
import constants from '../../constants';
import SplitText from '../../components/SplitText';
import HowerLine from '../../components/HowerLine';

export default function LineSale(props) {
    return (
        <div className='App'>
            <Container>
                <Row>
                    <LeftSection />
                    <RightSection />
                </Row>
            </Container>
        </div>
    )
}

const RightSection = () => {
    return (
        <Col>
            <Row>
                <Col sm={8}>
                    <h1 className='txt-bold' style={{ color: constants.COLOR.RED }}> Line Sale </h1>
                    <div className='top-spacing-section' />
                    <p> Line Sale Application is a perfect bookkeeping real-time application. Our app ensures a systematic & transparent way to do business.The accounts are made very easy use & manage.Provides an organized & hassle-free way of carrying out businessoperations digitally. </p>
                
            {/* <p> */}
                <SplitText label='Book-Keeping' showBarRight style={{ color: constants.COLOR.GREY }} />
                <SplitText label='Real-time Tracking' showBarRight style={{ color: constants.COLOR.GREY }} />
                <SplitText label='Reports' style={{ color: constants.COLOR.GREY }} />
            {/* </p> */}
            <div className='top-spacing-section' />
            {/* <p> */}
            <HowerLine>
                <h4 className='txt-bold' style={{ color: constants.COLOR.RED }}> Know more </h4>
            </HowerLine>
            {/* </p> */}
                </Col>
                <Col sm={4}>
                    <Animate >
                        <img src={MOCK_HUMAN_IMAGE} alt='MockHuman' className='img-linesale-human' />
                    </Animate>
                </Col>
            </Row>
        </Col>
    )
}

const LeftSection = () => {
    return (
        <Col sm={3}>
            <Animate>
                <img src={MOCK_IMAGE} alt='Mockup' className='img-card-image' />
            </Animate>
        </Col>
    )
}