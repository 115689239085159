import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import constants from '../../constants';
import pageValues from './pageValues';

export default function QA(props) {

    return (
        <div style={{ background: constants.COLOR.DARK_GREEN, paddingTop: '20px', paddingBottom: '20px' }} className='App'>
            <Container >
                <h1 className='txt-bold' style={{ color: constants.COLOR.WHITE }}> FAQ </h1>
                {pageValues.QA.map((item, index) => {
                    const ans_id = `ans${index}`
                    const ques_id = `ques${index}`
                    return (
                        <div style={{ marginTop: '10px' }}>
                            <Row>
                                <Col sm={11}>
                                    <text id={`ques${index}`}>{item.q}</text>
                                </Col>
                                <Col sm={1}>
                                    <text onClick={() => toggle(index)} id={`selector${index}`} style={{ fontWeight: "500", fontSize: "20px" }}> + </text>
                                </Col>
                            </Row>
                            <Row id={ans_id} style={{ display: 'none', padding: '20px', borderTop: `2px solid ${constants.COLOR.BLACK}`, marginTop: '10px' }}>
                                <text style={{ color: constants.COLOR.WHITE }} >
                                    {item.a}
                                </text>
                            </Row>
                        </div>
                    )
                })}
            </Container>
        </div>
    )
}

const toggle = (index) => {
    var answer = document.getElementById(`ans${index}`);
    var question = document.getElementById(`ques${index}`);
    const selector = document.getElementById(`selector${index}`)
    if (answer.style.display === "none") {
        answer.style.display = "block";
        question.style.color = constants.COLOR.YELLOW;
        selector.innerHTML = '-'
        return
    }
    answer.style.display = "none";
    question.style.color = constants.COLOR.BLACK;
    selector.innerHTML = '+';
    return
}