import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';

import Animate from '../../components/Animate';

import image_a_experience_accuracy from '../../assets/images/a_experience_accuracy.png'
import image_b_costeffective_accurate from '../../assets/images/b_costeffective_accurate.png'
import image_c_responsive from '../../assets/images/c_responsive.png'
import image_d_interface_design from '../../assets/images/d_interface_design.png'
import image_e_testing from '../../assets/images/e_testing.png'
import image_f_mobileapp_dev from '../../assets/images/f_mobileapp_dev.png'

const Section = ({ title = '', body = '', img = '' }) => {
    const id = title.split(' ').join('-');
    const alt = `${id.toLowerCase()}-image`;

    return (<Col sm={4} style={{ marginTop: '15px' }}>
        <Animate transition="fade-zoom-in" easing="ease-in-sine" duration="3000" id={`${id}-animate`}>
            <Row xs={12} id={`${id}-row1`} >
                <img id={`${id}-image`} src={img} className='descrition-logo' alt={alt} />
            </Row>
            <div className='top-spacing-section' id={`${id}-spacing`}></div>
        </Animate>
        <Row sm={12} id={`${id}-row2`}>
            <text id={`${id}-title`} className='txt-header txt-bold' > {title} </text>
        </Row>
        <Row xs={12} id={`${id}-row3`}>
            <text id={`${id}-body`} style={{ paddingRight: '20px' }}> {body} </text>
        </Row>
    </Col>)
}

export default function WhyUs(props) {
    console.log(`props`, props)
    return (
        <Container >
            <div className="app" ref={props.ref}>
                <Row>
                    <Section
                        title="Experience & Accuracy"
                        body="Our E-Commerce company called Ezeecharge is rated 4.8 out of 5, we manage chain - company distributor and retailer. Having 11 years of experience in E-commerce, we have 8000 + Satisfiedretailers using our Application every day. We have another software company called Royal Infotech since 20+ years. We have our own Industrial ERP & a lot of clients use our accounts & inventory management software."
                        img={image_a_experience_accuracy}
                    />

                    <Section
                        title="Cost-Effective & Accurate"
                        body="Understanding is one part of business, understanding and acting as per the need of the market is very important. Today’s business is with a very thin margin, & people management has become very expensive. Having a software and a person for data entry is very difficult, so we have developed Line Sale, a Real-time application with minimum monthly rental system."
                        img={image_b_costeffective_accurate}
                    />
                    <Section
                        title="Responsive"
                        body="Understanding is one part of business, understanding and acting as per the need of the market is very important. Today’s business is with a very thin margin, & people management has become very expensive. Having a software and a person for data entry is very difficult, so we have developed Line Sale, a Real-time application with minimum monthly rental system."
                        img={image_c_responsive}
                    />
                    <Section
                        title="Interface Design"
                        body="While creating user interface design, an initial detailed market study was conducted. After simplification, we analyzed the data system to put our efforts to study things in micro-levels. To understand the end-user difficulties, we took actions on how we can resolve the difficulty by implementing the processes in the application."
                        img={image_d_interface_design}
                    />
                    <Section
                        title="Testing"
                        body="We initially test the product in the house (at the office), more over our product is tested by people who are working in relevant businesses for a long time. The reason behind using this process is to understand the end-user better, their difficulties can be solved in real-time and their market experience can be applied to the system."
                        img={image_e_testing}
                    />
                    <Section
                        title="Mobile Application Development"
                        body="Providing the best solutions in the industry that makes your mobile app (Android & iOS), a remarkable experience by combining trending and elegant designs with the latest technologies depending on the best tools your business needs for success online. Transforming business problems and unique ideas into profitable software products is what we do best."
                        img={image_f_mobileapp_dev}
                    />


                </Row>
            </div>
        </Container>
    );
}