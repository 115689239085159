import React from 'react'
import TextField from '@material-ui/core/TextField';

export default ({
    type = "text",
    label = "",
    placeholder = " ",
    id = "",
    multiline = false,
    rows = 1,
    error = false,
    helperText = '',
    onChange = false
}) => {
    return (
        <div style={{ marginTop: '15px' }}>
            <TextField
                id={id}
                label={label}
                variant="outlined"
                multiline={multiline}
                rows={rows}
                placeholder={placeholder}
                error={error}
                helperText={helperText}
                onChange={e => onChange ? onChange(e) : console.log(e)}
                fullWidth
            />
        </div>
    )
}