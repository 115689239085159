import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MISSION_IMAGE from '../../assets/images/TW_Transparent.png'
import Animate from '../../components/Animate';
import MOBILE_MOCK from '../../assets/images/mobile_mock.png'
import constants from '../../constants';
import HowerLine from '../../components/HowerLine';

export default function Mission(props) {
    return (
        <div className='App'>
            <Container>
                <Row>
                    <LeftSection />
                    <RigthSection />
                </Row>
                <div className='top-spacing-section' />
                <Row sm={12}>
                    <HowerLine>
                        <h1 className='txt-bold align-centre' style={{ color: constants.COLOR.DARK_GREEN }}> Team Work </h1>
                    </HowerLine>
                </Row>
                <Row sm={12}>
                    <Animate style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={MISSION_IMAGE} className='mission-img align-centre' alt='mission-image' />
                    </Animate>
                </Row>

            </Container>
        </div>
    )
}

const LeftSection = () => {
    return (
        <Col sm={8}>
            <Container>
                <h1 className='txt-bold' style={{ color: constants.COLOR.DARK_GREEN }}> Mission </h1>
                <div className='top-spacing-section'></div>
                <p> Paper Free Team’s mission is providing trustworthy technology to every user. Our organization will assure you the best of services and security on your data. Our efforts to bring out intelligent ideas and build an effective and efficient application, best productivity, and business processes thus making the application get more of a personal touch. Our mission is to serve you by being efficient, productive, value for money. We will maintain the timeless value of privacy and preserve the ability for you to control your data. Any global changes in the application will be at a very low cost.  Small-scale businessmen and the self-employed intrapreneur will have an application that is effective, efficient, user-friendly, easy to understand and use. </p>
                <p> We will make sure you get the best of efficient and user-friendly applications from us, with changes whenever required for the particular product. Day-by-day, we work hard to earn your trust every day by focusing on privacy principles. We will provide you an application that has control over privacy and easy steps to make your daily work easier with choices. We are very transparent about your data policies; the data collected from you will be encrypted, and whenever an alteration is done to the application, we’ll keep you informed. </p>
            </Container>
        </Col>
    )
}

const RigthSection = () => {
    return (
        <Col sm={4}>
            <Animate>
                <img src={MOBILE_MOCK} alt='Mockup' className='img-mock-mobile' />
            </Animate>
        </Col>
    )
}