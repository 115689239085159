import React, { useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap';

import './styles.css';

import Button from '../../components/Button';
import Animate from '../../components/Animate';

import Header from './Header';
import HomePage from './HomePage';
import WhyUs from './WhyUs';
import Mission from './Mission';
import LineSale from './LineSale';
import constants from '../../constants';
import ContactUS from './ContactUs';
import SplitText from '../../components/SplitText';
import Parvo from './Parvo';
import QA from './QA';
import OurProcess from './OurProcess';

export default function Main(props) {
  const whyus = useRef(null);
  const home = useRef(null);
  const products = useRef(null);
  const pricing = useRef(null);
  const faq = useRef(null);
  const contact = useRef(null);
  const mission = useRef(null);
  const linesale = useRef(null);
  const parvo = useRef(null);
  const ourprocess = useRef(null);

  const controlScroll = section => {
    try {
      eval(section).current.scrollIntoView()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="App" ref={home}>
          <Header controlScroll={controlScroll}>
            <HomePage />
          </Header>
      </div>

      <div className='top-spacing' />
      <BoxSection />

      <div className='top-spacing' ref={whyus} />
      <Animate>
        <WhyUs />
      </Animate>
      <div className='top-spacing' ref={mission} />
      <Mission />
      <div className='top-spacing' ref={ourprocess} />
      <OurProcess />
      <div className='top-spacing' ref={linesale} />
      <LineSale />
      <div className='top-spacing' ref={parvo} />
      <Parvo />
      <div className='top-spacing' ref={faq} />
      <Animate>
        <QA />
      </Animate>
      <div className='top-spacing' />
      <div className='top-spacing' ref={contact} />
      <ContactUS />
      <div className='top-spacing' />
      <FooterSection />
      <div className='top-spacing' />

    </>
  );
}


const BoxSection = () => {
  return (
    <Animate>
      <div className='section-vertical box' style={{ backgroundColor: constants.COLOR.DARK_GREEN }}>
        <Container >
          <Row style={{ justifyContent: 'center', alignItems: "center", alignContent: 'center' }}>
            <h1 style={{ alignSelf: 'center', color: constants.COLOR.WHITE }} className=' txt'>Line Sale - Real-time Book-keep Application</h1>
          </Row>
          <div className='top-spacing-section ' />
          <Row style={{ justifyContent: 'center', alignItems: "center", alignContent: 'center' }}>
            <Button btnColor="dark-green" label={'Know more'} btnStyle={{ backgroundColor: constants.COLOR.YELLOW }} txtStyle={{ color: constants.COLOR.BLACK }} />
          </Row>
        </Container>
      </div>
    </Animate>
  )
}

const FooterSection = () => {
  const style = {
    color: constants.COLOR.WHITE,
    fontWeight: 600
  }
  return (
    <div className='section-vertical box' style={{ backgroundColor: constants.COLOR.DARK_GREEN, padding: '25px' }}>
      <Container >
        <Row style={{ justifyContent: 'center', alignItems: "center", alignContent: 'center' }}>
          <SplitText label={`©  Copyrights - 2021. Paper Free Solutions | Web Designed & Developed at The Freelancer Network`} style={{ ...style }} />
        </Row>
      </Container>
    </div>
  )
}