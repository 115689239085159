import React from 'react'
import constants from '../../constants'
import './styles.css'

export default function Button(props) {
    const {
        label,
        onClick,
        txtStyle = { color: 'white' },
        btnStyle = { backgroundColor: constants.COLOR.LIGHT_GREEN }
    } = props

    return (
        <button className='btn' onClick={() => onClick ? onClick : console.log('No clickable function')} style={btnStyle}>
            <span>
                <text className='txt-bold' style={txtStyle} > {label} </text>
            </span>
        </button>
    )
}