import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import IMAGE_SUPPORT from '../../assets/images/process_Support.png'
import IMAGE_DEVELOPMENT from '../../assets/images/process_Development.png'
import constants from '../../constants'

export default function OurProcess(props) {
    return (
        <div>
            <Container>
                <h1>Our Process</h1>
                <div className='top-spacing-section' />
                <Row>
                    <Col sm={9}>
                        <text>
                            Our process encourages us to pay attention to what people want and love. In Business, we love to have peace guaranteed, our solutions and products can bring out the best outfit to all the users, our products are very simplified, thus simplification creates love towards the product, using our products more and more brings absolute confidence in the user.
                        </text>
                    </Col>
                </Row>
                <div className='top-spacing-section' />
                <Row>
                    <Col>
                        <Image src={IMAGE_DEVELOPMENT} />
                    </Col>
                    <Col>
                        <MiniHeader title='User interface Design' index={1}>
                            While designing the user interface, we keep our user in our mind, this process will help us to give life to the designs
                        </MiniHeader>
                        <MiniHeader title='Database Development' index={2}>
                            Developing the core functionalities, database, servers etc. to make the project a market-ready product.
                        </MiniHeader>
                        <MiniHeader title='API' index={3}>
                            Application Programming Interface, which is a software intermediary and allows two applications to talk to each other.  Secured API enables the product to communicate confidently.
                        </MiniHeader>
                    </Col>
                </Row>
                <div className='top-spacing-section' />
                <Row>
                    <Col>
                        <MiniHeader title='Testing' index={1}>
                            Initial product/apps testing is done at our development centre we validate every aspect of the product. Once the product testing is done, we give the product to real-time users to find out real-time bugs, User satisfaction is the best method to confirm the product is well tested.
                        </MiniHeader>
                        <MiniHeader title='Release & Implementation' index={2}>
                            Once the user satisfaction is confirmed, we release the product live in the app store.
                        </MiniHeader>
                        <MiniHeader title='Customer Support' index={3}>
                            Distinguishable users find difficulties in understanding the features, the customer is provided with live support and digital support. We serve our clients continuously for any feature additions or upgrades.
                        </MiniHeader>
                    </Col>
                    <Col>
                        <Image src={IMAGE_SUPPORT} />
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

const MiniHeader = ({ title, index, children }) => {
    return (
        <div style={{ marginTop: '20px', paddingTop: '20px' }}>
            <text style={{ fontWeight: '800', color: constants.COLOR.GREY }}>0{index}</text>
            < h4 > {title} </h4 >
            <text>
                {children}
            </text>
        </div>
    )
}
const Image = ({ src }) => <img src={src} alt='MockHuman' className='img-mock-mobile' />