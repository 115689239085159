import React from 'react'
import './styles.css'

export default function Animate(props) {
    const { transition = "fade-down", easing = "linear", duration = "1000", style = {} } = props
    return (
        <div
            data-aos={transition}
            data-aos-easing={easing}
            data-aos-duration={duration}
            style={style}
            data-aos-once={false}
        >
            {props.children}
        </div>
    )
}