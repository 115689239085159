import constants from "../../constants"
import Language from './language'

export default function translate(text) {
    const selectedLanguage = localStorage.getItem(constants.STORAGE.LANGUAGE) || "english"
    try {
        return Language[selectedLanguage][text]
    } catch (err) {
        return Text
    }
}
