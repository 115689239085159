export default {
    LIGHT_GREEN: "#31c844",
    DARK_GREEN: "#009444",
    RED: "#fd0167",
    PINK: "#e3538b",
    GREY: "#9ea1ac",
    DARK_GREY: "#939393",
    WHITE: "#ffffff",
    BLACK: "#000000",
    YELLOW: "#ffd42d",
    VIOLET: "#8b0094",
}