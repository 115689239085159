import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Animate from '../../components/Animate';
import MOBILE_MOCK from '../../assets/images/mock_parvo.png'
import constants from '../../constants';
import SplitText from '../../components/SplitText';
import HowerLine from '../../components/HowerLine';

export default function Parvo(props) {
    return (
        <div className='App'>
            <Container>
                <Row>
                    <LeftSection />
                    <RigthSection />
                </Row>
            </Container>
        </div>
    )
}

const LeftSection = () => {
    return (
        <Col sm={8}>
            <Container>
                <h1 className='txt-bold' style={{ color: constants.COLOR.VIOLET }}> PARVO </h1>
                <div className='top-spacing-section' />
                <text>A unique and all in one app to connect our community.<br /> </text>
                <text>A platform that connects everyone, a platform that helps networking for our commune.<br /> </text>
                <text>A project that we are enthusiastically developing with all our hearts in it,<br /> </text>
                <text>Parvo, one of our biggest projects which is currently under development.<br /> </text>
                <div className='top-spacing-section' />
                <SplitText label='Unique' showBarRight style={{ color: constants.COLOR.GREY }} />
                <SplitText label='Connect' showBarRight style={{ color: constants.COLOR.GREY }} />
                <SplitText label='Network' showBarRight style={{ color: constants.COLOR.GREY }} />
                <SplitText label='Community' style={{ color: constants.COLOR.GREY }} />
                <div className='top-spacing-section' />
                <HowerLine>
                <h4 className='txt-bold' style={{ color: constants.COLOR.VIOLET }}> Coming Soon </h4>
                </HowerLine>
            </Container>
        </Col>
    )
}

const RigthSection = () => {
    return (
        <Col style={{ display: 'flex' }} sm={4}>
            <div style={{ justifyContent: 'center' }}>
                <Animate>
                    <img src={MOBILE_MOCK} alt='Mockup' className='img-mock-parvo' />
                </Animate>
            </div>
        </Col>
    )
}