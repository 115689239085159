import STORAGE from './storage';
import COLOR from './colors'

const ENV = {
    development: {
        HOMPAGE: 'http://localhost:4000/',
    },
    production: {
        HOMPAGE: 'http://paper-free-solutions.herokuapp.com/',
    }
}

const { HOMPAGE } = ENV[process.env.NODE_ENV]

export default {
    STORAGE,
    COLOR,
    HOMPAGE,
}