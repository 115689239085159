import React,{ useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import PFS_withText from '../../assets/logos/PFS_withText.png';
import { ReactComponent as CloseMenu } from "../../assets/svgs/x.svg";
import { ReactComponent as MenuIcon } from "../../assets/svgs/menu.svg";
import Logo from "../../assets/logos/PFS_withText.png";
import "./mobileheader.css";
 
import pageValues from './pageValues';

const {innerHeight, innerWidth} = window;


function Header({ children, controlScroll }){
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    return (
        <div className="App">
        <Container style={{ marginTop: innerHeight/25 }}>
            {
                innerWidth >= 760 
                ?
                <Row>
                    <Col sm={4}> <img src={PFS_withText} alt="logo" className="App-Logo" /> </Col>
                    <Col>
                        <Row>
                            {pageValues.NavigationParams.map(item =>
                                <Col sm={2}>
                                    <a onClick={() => controlScroll(item.route)} >
                                        <text className="txt-bold">{item.title}</text>
                                    </a>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                :
                <div className="header">
                    <div className="logo-nav">
                        <div className="logo-container">
                        <a href="#">
                            <img src={Logo} className="logo" />
                        </a>
                        </div>
                        <ul className={click ? "nav-options active" : "nav-options"}>
                        {pageValues.NavigationParams.map(item =>
                            <li className="option" onClick={() => {
                                controlScroll(item.route)
                                closeMobileMenu();
                                }} >
                                <a>{item.title}</a>
                            </li>
                        )} 
                        </ul>
                    </div>
                    <ul className="signin-up">
                        <li className="sign-in" onClick={closeMobileMenu}>
                        <a href="#">SIGN-IN</a>
                        </li>
                        <li onClick={closeMobileMenu}>
                        <a href="" className="signup-btn">
                            SIGN-UP
                        </a>
                        </li>
                    </ul>
                    <div className="mobile-menu" onClick={handleClick}>
                        {click ? (
                        <CloseMenu className="menu-icon" />
                        ) : (
                        <MenuIcon className="menu-icon" />
                        )}
                    </div>
                    </div>
            }
            {children}
        </Container>
        </div>
    );
}

export default Header;


