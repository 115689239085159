import React from 'react'
import { Row } from 'react-bootstrap'
import constants from '../../constants'

const availableLanguages = ["English", "Kannada", "Hindi"]
const languageSelected = localStorage.getItem(constants.STORAGE.LANGUAGE) || "english"

export default function LanguageSelector(props) {
    return (
        <div className="position-right lang-selector section-vertical" style={{zIndex: '9999'}}>
            <Row>
                <text className='lang-txt-bold'></text>
                <text>Language - </text>
                {availableLanguages.map((language, index) => <Language language={language} showBar={availableLanguages.length > 0 && availableLanguages.length - 1 !== index} />)}
            </Row>
        </div>
    )
}

function Language({ language = '', showBar = false }) {
    var style = { marginLeft: '5px' }
    if ((languageSelected === language.toLowerCase())) style.color = constants.COLOR.DARK_GREEN

    return (
        // <Col >
        <text onClick={() => languageChange(language)} className='bold-text' style={style}> {` ${language} `}
            <text> {showBar ? " | " : null} </text>
        </text>
        // </Col>
    )
}

const languageChange = (language = 'English') => {
    if (!language && !availableLanguages.includes(language)) return

    localStorage.setItem(constants.STORAGE.LANGUAGE, language.toLowerCase())
    window.location.reload();
}